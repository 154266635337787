<template>
  <div ref="editorContent"></div>
</template>
<script>
import axios from "../commons/AxiosMethod.js"
import E from "wangeditor"
export default {
  name: "header",
  data () {
    return {
      editor: null
    }
  },
  props: {
    height: 600,
  },
  mounted () {
    // 这个是页面创建的时候，就执行相关的方法
    this.Editor()

    // //设置内容
    // this.editor.txt.html("<p>用 JS 设置的内容</p>");
    // //获取html内容
    //  editorModel.editor.txt.html(result.Data.Content);
    // //获取text内容
    // this.editor.txt.text();
    // //获取json
    // this.editor.txt.getJSON();
    // //清空内容
    // this.editor.txt.clear();
  },
  methods: {
    Editor () {
      var That = this
      this.editor = new E(this.$refs.editorContent)
      this.editor.customConfig = {
        onchange: function (html) {
          That.Content = html
        },
        uploadImgServer: "/api/UploadImg", // 上传图片到服务器
        uploadFileName: "Content", // 后端使用这个字段获取图片信息
        uploadImgMaxLength: 1 // 限制一次最多上传 1 张图片
      }
 
      this.editor.config.height = this.height;
      this.editor.config.uploadImgMaxSize = 20 * 1024 * 1024

      this.editor.config.uploadImgServer =
        axios.GetUrl() + "/api/Upload/EditorImageFile"

      this.editor.config.uploadImgHeaders = {
        Authorization: localStorage.getItem("Authentication_Header")
      }

      this.editor.create()
    }
  }
}
</script>
<style scoped>
</style>
